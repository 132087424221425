import React from "react"
import Layout from "../../components/layout"
import PcpHero from "../../components/PcpHero"
import Breadcrumbs from "../../components/Breadcrumbs"
import VideoModule from "../../components/VideoModule"
import HowHubbleWorksCarousel from "../../components/HowHubbleWorksCarousel"
import SEO from "../../components/seo"
import avgProducts from "../../services/avg-products.json"
import { contactLenses as getContactLenses } from "../../components/LensCompare/mockedData"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../styles/pages/hubble-lenses.scss"

import PcpList, { formatPcpItem } from "../../components/PcpList"
import PcpSection from "../../components/PcpSection"
import usePromo from '../../utils/usePromo'
import useIsLifemart from "../../components/Lifemart/hooks/useIsLifemart";
import useIsMilitary from "../../components/Military/hooks/useIsMilitary";

const DailyPcp = ()  => {
  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hubble Contacts | How We Make Contact Lenses Easy",
      "description": "At Hubble, we believe buying contact lenses should be easy. That’s why we’ve made Hubble’s personalized subscription service the easiest contacts experience in the world.",
      "thumbnailUrl": "https://i.ytimg.com/vi/w4YCLxsUxyI/hqdefault.jpg",
      "uploadDate": "2023-03-20",
      "duration": "PT1M15S",
      "contentUrl": "https://www.hubblecontacts.com",
      "embedUrl": "https://www.youtube.com/embed/w4YCLxsUxyI"

    },
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/contact-lenses/",
      "@type": "webpage",
      "name": "Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription",
      "description": "Experience the convenience of purchasing daily contact lenses online with Hubble Contacts! Enjoy clear vision and comfort with easy ordering and delivery!",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble"
      }
    }
  ]

  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const contactLenses = getContactLenses(!isLifemart && !isMilitary)
  let products = [...contactLenses]
  avgProducts.forEach(product => {
    product.options.forEach((option, optionIndex) => {
      const title = option?.title?.replace(/6pk|12pk|24pk|30pk|90pk/, '')?.trim()
      const isDaily = title.toLocaleLowerCase().includes('1-day') || title.toLocaleLowerCase().includes('oneday')
      if (isDaily) {
        const pcpItem = formatPcpItem(product, option, optionIndex)
        products.push(pcpItem)
      }
    })
  })

  const isPromo = usePromo()

  const promos = [
    {
      index: 5,
      heading: "Hydro by Hubble",
      title: "Now $4.99",
      buttonText: "Shop Hydro by Hubble",
      text: `Our most popular daily lens just got better. Get your first order for 80% off, at just 4.99/eye.`,
      linkTo: "/contact-lenses/hydro-by-hubble-lenses/",
      imageClass: "promo-3"
    },
    {
      index: 9,
      heading: "SkyHy by hubble",
      title: "Our most breathable daily contact lenses.",
      text: "Made from silicone hydrogel, the material most prescribed by doctors.",
      buttonText: "Shop SkyHy by Hubble",
      linkTo: "/contact-lenses/skyhy-by-hubble-lenses/",
      imageClass: "promo-4",
    }
  ]

  return (
    <Layout>
      <SEO
        title="Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription"
        description="Experience the convenience of purchasing daily contact lenses online with Hubble Contacts! Enjoy clear vision and comfort with easy ordering and delivery!"
        schemaMarkup={schemaMarkup}
      />
      <PcpHero
        heroText={<>Daily Contact<br/> Lenses</>}
        heroImage={"PCP/daily-desktop.jpg"}
        background="purple"
        bannerClass="purple promo-banner pcp"
        bannerText="Start your subscription now for <strong>UP TO 60% OFF</strong> your first order."
        shopNowInBanner
      />
      <div className="pcp-container">
        <Breadcrumbs links={[{ to: "/", label: "Homepage" }, { label: "Daily Contact Lenses" }]} />
        <PcpList {...{products, promos}} />
      </div>
      <HowHubbleWorksCarousel color="purple"/>
      <div className="video-container">
        <VideoModule
          type={"contacts"}
          video={"https://www.youtube.com/embed/NKxNYzCRGwg"}
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
      </div>
      <PcpSection
        title="daily contact lenses"
        text={
          `Transform your perspective with daily contact lenses through Hubble Contacts.
          Eliminate the inconvenience of cleaning and storing lenses — just insert a new pair each day for clear vision.
          Order your daily contact lenses online today.`
        }
      />
    </Layout>
  )
}

export default DailyPcp
